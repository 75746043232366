/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx, Themed } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetDivider" }>;
}

const DividerWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => (
  <WidgetWrapper
    isFirst={isFirst}
    isLast={isLast}
    isNested={isNested}
    containerSize={containerSize}
    widget={widget}
    prevWidget={prevWidget}
    nextWidget={nextWidget}
  >
    <Themed.hr />
  </WidgetWrapper>
);

export const query = graphql`
  fragment WidgetDivider on WidgetDivider {
    id
    __typename
  }
`;

export default memo(DividerWidget);
